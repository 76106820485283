<template>
  <v-row justify="start">
    <v-dialog v-model="dialogView" persistent max-width="300px">
      <v-card>
        <v-toolbar color="indigo" dense>
          <span class="headline white--text">Coach</span>
          <v-spacer></v-spacer>
          <v-btn dark class="ma-2" @click="$emit('false')" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="form" lazy-validation class="pa-3">
          <v-row>
            <v-col cols="12" md="6">
              <section>
                <small>Nama kelompok</small>
                <p>{{ profile.nama }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <router-link :to="`group-detail/${profile.id}`">
                <v-btn color="indigo" small dark text>lihat detail</v-btn>
              </router-link>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" v-if="profile.pelatihan">
              <section>
                <small>Pelatihan</small>
                <p>{{ profile.pelatihan.nama }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6" v-if="profile.pelatihan">
              <section>
                <small>Coach</small>
                <p>{{ profile.mentor.name }}</p>
              </section>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-dialog v-model="progress" hide-overlay persistent width="250">
        <v-card class="indigo" dark>
          <v-card-text>
            silahkan tunggu...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "viewgroup",

  props: ["dialogView", "profile"],

  data() {
    return {
      progress: false
    };
  },

  methods: {}
};
</script>

<style></style>
