<template>
  <div>
    <v-progress-circular
      indeterminate
      size="20"
      :color="propsColor"
      class="mr-2 ml-2"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "loader",
  props: ["propsColor"]
};
</script>

<style></style>
