import gql from "graphql-tag";

//  CREATE MENTOR
export const ADD_MENTOR = gql`
  mutation(
    $name: String!
    $nama_lengkap: String!
    $email: String!
    $password: String!
    $password_no_hash: String
    $role_id: roleId!
    $created_by: Int!
  ) {
    createMentor(
      input: {
        user: {
          create: {
            name: $name
            nama_lengkap: $nama_lengkap
            email: $email
            password: $password
            password_no_hash: $password_no_hash
            role_id: $role_id
            created_by: $created_by
          }
        }
      }
    ) {
      id
      user_id
      user {
        id
        name
        nama_lengkap
        email
      }
    }
  }
`;

// READ peserta
export const GET_MENTOR = gql`
  query($page: Int!, $first: Int!, $search: String) {
    allMentor(
      page: $page
      first: $first
      search: { search: $search, role_id: 3 }
    ) {
      data {
        id
        name
        nama_lengkap
        email
        pembimbing {
          id
        }
      }
      paginatorInfo {
        currentPage
        total
      }
    }
  }
`;

// READ peserta
export const COUNT_MENTOR = gql`
  query($page: Int!, $first: Int!) {
    scopeMentor(page: $page, first: $first) {
      paginatorInfo {
        total
      }
    }
  }
`;

// UPDATE peserta
export const EDIT_MENTOR = gql`
  mutation(
    $id: ID!
    $nama_lengkap: String!
    $connect: ID!
    $id_profile: ID!
    $email: String!
  ) {
    updateMentor(
      input: {
        id: $id
        nama_lengkap: $nama_lengkap
        email: $email
        pembimbing: { connect: $connect, update: { id: $id_profile } }
      }
    ) {
      id
      name
      nama_lengkap
      email
      pembimbing {
        id
      }
    }
  }
`;

// DELETE mentor
export const DELETE_MENTOR = gql`
  mutation($id: ID!) {
    deleteMentor(id: $id) {
      id
    }
  }
`;
// ALL GRUP
export const ALL_GRUP = gql`
  query($first: Int!, $page: Int!, $search: String) {
    allGrup(first: $first, page: $page, search: $search) {
      data {
        id
        nama
        pelatihan {
          id
          nama
          modul {
            id
            tipe
          }
        }
        user {
          id
          nama_lengkap
          email
        }
      }
      paginatorInfo {
        currentPage
        total
      }
    }
  }
`;
