<template>
  <div class="container-admin">
    <nav class="d-flex justify-start pa-5">
      <addMentor v-on:refetch="refetchMentor()" />
    </nav>
    <div class="temp-list d-flex justify-center">
      <v-card class="temp-table ma-5">
        <v-card-title>
          Daftar Coach
          <v-spacer></v-spacer>
          <v-text-field
            v-model="find"
            append-icon="mdi-magnify"
            label="Cari"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <div>
          <v-data-table
            :loading="loadTable"
            :headers="headers"
            :items="allMentor.data"
            hide-default-footer
            :page.sync="page"
            :items-per-page="limit"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex justify-space-around">
                <v-btn fab dark small color="cyan" @click="editPage(item)">
                  <v-icon dark small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  small
                  color="red"
                  @click.prevent="deleteConfirm(item)"
                >
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="paginate"></v-pagination>
          </div>
        </div>
      </v-card>
    </div>
    <EditMentor
      v-bind:profile="profile"
      v-bind:idProfile="idProfile"
      v-bind:dialogView="dialogView"
      v-bind:compareEmail="compareEmail"
      v-on:false="dialogFalse()"
    />
  </div>
</template>

<script>
import addMentor from "../../components/Teacher/Modal/addMentor";
import Swal from "sweetalert2";
import { GET_MENTOR, DELETE_MENTOR } from "../../graphql/Mentor";
import EditMentor from "../../components/Teacher/Modal/editMentor";

export default {
  name: "listMentor",

  // apollo query
  apollo: {
    allMentor: {
      query: GET_MENTOR,
      variables() {
        return { page: this.page, first: this.limit, search: this.find };
      },
      error(err) {
        console.log(err);
      },
    },
  },
  computed: {
    loadTable() {
      return this.$apollo.queries.allMentor.loading;
    },
    paginate() {
      let page = null;
      if (this.allMentor.paginatorInfo) {
        let total = this.allMentor.paginatorInfo.total;
        page = Math.ceil(total / this.limit);
      }
      return page;
    },
  },
  components: {
    addMentor,
    EditMentor,
  },

  data() {
    return {
      allMentor: [],
      page: 1,
      limit: 10,
      idProfile: null,
      dialogView: false,
      compareEmail: "",
      name: "",
      search: "",
      find: "",
      profile: [],
      headers: [
        { text: "id", value: "id" },
        { text: "Nama Pengguna", value: "name" },
        {
          text: "Nama Lengkap",
          align: "start",
          sortable: false,
          value: "nama_lengkap",
        },
        { text: "Email", value: "email" },
        { text: "Aksi", value: "actions" },
      ],
    };
  },

  created() {},
  methods: {
    editPage(item) {
      this.profile = item;
      this.compareEmail = item.email;
      this.idProfile = item.pembimbing.id;
      this.dialogView = true;
    },
    refetchMentor() {
      this.$apollo.queries.allMentor.refetch();
    },
    deleteConfirm(item) {
      Swal.fire({
        title: "Yakin hapus coach?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus data!",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$apollo
              .mutate({
                mutation: DELETE_MENTOR,
                variables: {
                  id: item.id,
                },
              })
              .then(() => {
                this.refetchMentor();
                Swal.fire({
                  icon: "success",
                  title: "Coach telah terhapus",
                  showConfirmButton: false,
                  timer: 1000,
                  position: "top",
                  toast: true,
                });
              });
          }
        })
        .then(() => {
          Swal.fire({
            icon: "error",
            title: "Coach sudah dalam pelatihan!",
            showConfirmButton: false,
            timer: 1000,
            position: "top",
            toast: true,
          });
        });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    dialogFalse() {
      this.dialogView = false;
      this.refetchMentor();
    },
  },
};
</script>

<style scoped>
.profile-mentor {
  display: none;
  height: max-content;
}
.temp-table {
  width: 100%;
}
</style>
