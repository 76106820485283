<template>
  <div class="container-admin">
    <nav class="d-flex justify-start pa-5">
      <!-- <addMentor /> -->
    </nav>
    <div class="temp-list d-flex justify-center">
      <v-card class="temp-table ma-5">
        <v-card-title>
          Daftar Peserta
          <v-spacer></v-spacer>
          <v-text-field
            v-model="find"
            append-icon="mdi-magnify"
            label="Cari"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <div>
          <v-data-table
            :loading="loadTable"
            :headers="headers"
            :items="allStudent.data"
            hide-default-footer
            :page.sync="page"
            :items-per-page="limit"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex justify-space-around">
                <v-btn fab dark small color="cyan" @click="editPage(item)">
                  <v-icon dark small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  small
                  color="red"
                  @click.prevent="deleteConfirm(item)"
                >
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="paginate"></v-pagination>
          </div>
        </div>
      </v-card>
    </div>
    <EditPeserta
      v-bind:profile="profile"
      v-bind:idProfile="idProfile"
      v-bind:dialogView="dialogView"
      v-bind:compareEmail="compareEmail"
      v-on:false="dialogFalse()"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { GET_STUDENT, DELETE_STUDENT } from "../../graphql/Student";
import EditPeserta from "../../components/Teacher/Modal/editStudent";
export default {
  name: "listStudent",

  // apollo query
  apollo: {
    allStudent: {
      query: GET_STUDENT,
      variables() {
        return { page: this.page, first: this.limit, search: this.find };
      }
    }
  },
  components: {
    EditPeserta
  },
  computed: {
    loadTable() {
      return this.$apollo.queries.allStudent.loading;
    },
    paginate() {
      let page = null;
      if (this.allStudent.paginatorInfo) {
        let total = this.allStudent.paginatorInfo.total;
        page = Math.ceil(total / this.limit);
      }
      return page;
    }
  },
  data() {
    return {
      allStudent: [],
      name: "",
      limit: 10,
      find: "",
      page: 1,
      search: "",
      profile: [],
      dialogView: false,
      idProfile: null,
      compareEmail: "",
      headers: [
        { text: "id", value: "id" },
        { text: "Nama Pengguna", value: "name" },
        {
          text: "Nama Lengkap",
          align: "start",
          sortable: false,
          value: "nama_lengkap"
        },
        { text: "Email", value: "email" },
        { text: "Aksi", value: "actions" }
      ]
    };
  },
  created() {
    this.$apollo.queries.allStudent.refetch();
  },
  watch: {
    /* eslint-disable no-unused-vars */
    async $route(to, from) {
      /* eslint-enable no-unused-vars */
      console.log(to);
      await this.$apollo.queries.allStudent.refetch();
    }
  },
  methods: {
    editPage(item) {
      this.profile = item;
      this.compareEmail = item.email;
      this.idProfile = item.student.id;
      this.dialogView = true;
    },
    refetchStudent() {
      this.$apollo.queries.allStudent.refetch();
    },

    deleteConfirm(item) {
      Swal.fire({
        title: "Yakin hapus peserta?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus data!",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: DELETE_STUDENT,
              variables: {
                id: item.id
              }
            })
            .then(() => {
              this.refetchStudent();
              Swal.fire({
                icon: "success",
                title: "Peserta telah terhapus",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    dialogFalse() {
      this.dialogView = false;
      this.refetchStudent();
    }
  }
};
</script>

<style scoped>
.profile-peserta {
  display: none;
  width: 30%;
  height: max-content;
}
.temp-table {
  width: 100%;
}
</style>
