<template>
  <div class="container-admin">
    <nav class="d-flex justify-start pa-5">
      <!-- <addMentor /> -->
    </nav>
    <div class="temp-list d-flex justify-center">
      <v-card class="temp-table ma-5">
        <v-card-title>
          Daftar Kelompok
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <div>
          <v-data-table
            :loading="loadTable"
            :headers="headers"
            :items="grupByTeacher"
            :search="search"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex justify-space-around">
                <v-btn fab dark small color="cyan" @click="editPage(item)">
                  <v-icon dark small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  small
                  color="red"
                  @click.prevent="deleteConfirm(item)"
                >
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
    <EditGroup
      v-bind:profile="profile"
      v-bind:dialogView="dialogView"
      v-on:false="dialogFalse()"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { GET_GROUP, DELETE_GROUP } from "../../graphql/Group";
import EditGroup from "../../components/Teacher/Modal/editGroup";

export default {
  name: "listGroup",

  // apollo query
  apollo: {
    grupByTeacher: {
      variables() {
        return { teacher_id: this.idTeacher };
      },
      query: GET_GROUP
    }
  },
  computed: {
    loadTable() {
      return this.$apollo.queries.grupByTeacher.loading;
    },
    idTeacher() {
      return parseInt(localStorage.getItem("id"));
    }
  },
  components: {
    EditGroup
  },
  data() {
    return {
      grupByTeacher: [],
      name: "",
      search: "",
      profile: [],
      dialogView: false,
      headers: [
        { text: "id", value: "id" },
        { text: "Nama Kelompok", value: "nama" },
        {
          text: "Pelatihan",
          align: "start",
          sortable: false,
          value: "pelatihan.nama"
        },
        { text: "Coach", value: "mentor.name" },
        { text: "Aksi", value: "actions" }
      ]
    };
  },
  created() {
    this.$apollo.queries.grupByTeacher.refetch();
  },
  watch: {
    /* eslint-disable no-unused-vars */
    async $route(to, from) {
      /* eslint-enable no-unused-vars */
      console.log(to);
      await this.$apollo.queries.grupByTeacher.refetch();
    }
  },
  methods: {
    editPage(item) {
      this.profile = item;
      this.dialogView = true;
    },
    deleteConfirm(item) {
      console.log(this.profile);
      Swal.fire({
        title: "Yakin hapus kelompok?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus data!",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: DELETE_GROUP,
              variables: {
                id: item.id
              }
            })
            .then(() => {
              this.$apollo.queries.grupByTeacher.refetch();
              Swal.fire({
                icon: "success",
                title: "Kelompok telah terhapus",
                showConfirmButton: false,
                timer: 2000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },

    dialogFalse() {
      this.dialogView = false;
    }
  }
};
</script>

<style scoped>
.profile-teacher {
  display: none;
  height: max-content;
}
.temp-table {
  width: 100%;
}
</style>
