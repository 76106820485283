<template>
  <v-row justify="start">
    <v-dialog v-model="dialogView" persistent max-width="300px">
      <v-card v-if="profile">
        <v-toolbar color="indigo" dense>
          <span class="headline white--text">Coach</span>
          <v-spacer></v-spacer>
          <v-btn dark class="ma-2" @click="$emit('false')" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="form" lazy-validation class="pa-3">
          <v-row>
            <v-col cols="12" md="6">
              <section>
                <small>Nama Lengkap</small>
                <p>{{ profile.nama_lengkap }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>Nama Pengguna</small>
                <p>{{ profile.name }}</p>
              </section>
            </v-col>
          </v-row>
          <v-text-field
            v-model="profile.email"
            :counter="100"
            label="Email"
            required
          ></v-text-field>

          <div>
            <v-btn color="indigo" dark class="ma-2" @click="updatePeserta">
              resend email
            </v-btn>
          </div>
        </v-form>
      </v-card>
      <v-dialog v-model="progress" hide-overlay persistent width="250">
        <v-card class="indigo" dark>
          <v-card-text>
            silahkan tunggu...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { EDIT_MENTOR } from "../../../graphql/Mentor";
export default {
  name: "addGroup",

  props: ["dialogView", "profile", "idProfile", "compareEmail"],

  data() {
    return {
      progress: false
    };
  },

  methods: {
    updatePeserta() {
      this.progress = true;
      if (this.compareEmail != this.profile.email) {
        const id = parseInt(this.profile.id);
        const id_profile = parseInt(this.idProfile);
        this.$apollo
          .mutate({
            mutation: EDIT_MENTOR,
            variables: {
              id: id,
              nama_lengkap: this.profile.nama_lengkap,
              email: this.profile.email,
              id_profile: id_profile,
              connect: id
            }
          })
          .then(() => {
            this.$store
              .dispatch("resendEmail", {
                id: parseInt(this.profile.id)
              })
              .then(() => {
                this.progress = false;
                Swal.fire({
                  icon: "success",
                  title: "Email telah dikirim ulang",
                  showConfirmButton: false,
                  timer: 3000,
                  toast: true,
                  position: "top"
                });
                this.$emit("false");
              });
          })
          .catch(err => {
            console.log(err);
            ("errornya di graph");
            this.progress = false;
          });
      } else {
        this.$store
          .dispatch("resendEmail", {
            id: parseInt(this.profile.id)
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "email telah dikirim ulang",
              showConfirmButton: false,
              timer: 3000,
              toast: true,
              position: "top"
            });
            this.progress = false;
            this.$emit("false");
          });
      }
    }
  }
};
</script>

<style></style>
