<template>
  <v-row justify="start">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" dark color="indigo">
          <v-icon dark> mdi-plus </v-icon> Tambah coach
        </v-btn>
      </template>
      <v-card>
        <v-toolbar color="indigo">
          <span class="headline white--text">Coach</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nama Lengkap"
                  required
                  v-model="nama_lengkap"
                  :error-messages="fullNameErr"
                  @input="$v.nama_lengkap.$touch()"
                  @blur="$v.nama_lengkap.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Username"
                  required
                  v-model="nama"
                  :error-messages="nameErr"
                  @input="$v.nama.$touch()"
                  @blur="$v.nama.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  required
                  v-model="email"
                  :error-messages="emailErr"
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  :error-messages="PasswordError"
                  required
                  label="Kata sandi"
                  counter
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Keluar
          </v-btn>

          <v-btn color="indigo" dark @click="addPeserta">
            <span v-if="loading" class="d-flex">
              <Loading propsColor="white" /> Memuat
            </span>
            <span v-else> Tambah </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import Loading from "../../_base/loader";
import { ADD_MENTOR } from "../../../graphql/Mentor";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "addMentr",
  components: {
    Loading
  },
  mixins: [validationMixin],
  validations: {
    nama_lengkap: { required },
    nama: { required },
    email: { required, email },
    password: { required, minLength: minLength(8) }
  },
  computed: {
    fullNameErr() {
      const errors = [];
      if (!this.$v.nama_lengkap.$dirty) return errors;
      !this.$v.nama_lengkap.required &&
        errors.push("Nama lengkap tidak boleh kosong!");
      return errors;
    },
    nameErr() {
      const errors = [];
      if (!this.$v.nama.$dirty) return errors;
      !this.$v.nama.required && errors.push("Username tidak boleh kosong!");
      return errors;
    },
    emailErr() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("email tidak valid");
      !this.$v.email.required && errors.push("Email tidak boleh kosong!");
      return errors;
    },
    PasswordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Kata sandi minimal 8 karakter!");
      !this.$v.password.required && errors.push("Kata sandi harus di isi!");
      return errors;
    }
  },
  data: () => ({
    id: null,
    nama: "",
    nama_lengkap: "",
    email: "",
    password: "",
    dialog: false,
    show: false,
    created_by: "",
    loading: false
  }),
  methods: {
    addPeserta() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        const name = this.nama;
        const nama_lengkap = this.nama_lengkap;
        const email = this.email;
        const password = this.password;
        const role_id = "MENTOR";
        const created_by = parseInt(localStorage.getItem("id"));
        this.$apollo
          .mutate({
            mutation: ADD_MENTOR,
            variables: {
              name: name,
              nama_lengkap: nama_lengkap,
              email: email,
              password: password,
              password_no_hash: password,
              role_id: role_id,
              created_by: created_by
            }
          })
          .then(() => {
            this.nama = "";
            this.nama_lengkap = "";
            this.email = "";
            this.password = "";
            this.dialog = false;
            this.$emit("refetch");
            this.loading = false;
            this.$v.$reset();
            Swal.fire({
              icon: "success",
              title: "Anda telah menambahkan coach",
              showConfirmButton: false,
              timer: 3000,
              position: "top",
              toast: true
            });
          })
          .catch((error) => {
            console.log(error.message);
            if (error.message == "GraphQL error: Internal server error"){
              Swal.fire({
              icon: "error",
              title: "Nama Coach sama dengan nama Widyaiswara",
              showConfirmButton: false,
              timer: 3000,
              position: "top",
              toast: true
            });
            }
            else{
              Swal.fire({
                icon: "error",
                title: "Email sudah digunakan",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true
              });
            }
          })
          .then(() => {
            this.dialog = false;
            this.$emit("refetch");
            this.loading = false;
            this.$v.$reset();
          });
      }
    }
  }
};
</script>

<style></style>
