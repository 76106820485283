<template>
  <v-row justify="start">
    <v-dialog v-model="dialogView" persistent max-width="500px">
      <v-card v-if="profile">
        <v-toolbar color="indigo" dense>
          <span class="headline white--text">Peserta</span>
          <v-spacer></v-spacer>
          <v-btn dark class="ma-2" @click="$emit('false')" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="form" lazy-validation class="pa-3">
          <v-row>
            <v-col cols="12" md="6">
              <section>
                <small>Nama Lengkap</small>
                <p>{{ profile.nama_lengkap }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>Nama Pengguna</small>
                <p>{{ profile.name }}</p>
              </section>
            </v-col>
          </v-row>
          <v-text-field
            v-model="profile.email"
            :counter="100"
            label="Email"
            required
          ></v-text-field>
          <div>
            <v-btn
              color="indigo"
              dark
              class="ma-2"
              @click="updatePeserta"
              v-if="role == 2"
            >
              resend email
            </v-btn>
          </div>
          <v-row v-if="profile.student">
            <v-col cols="12" md="6">
              <section>
                <small>NIK</small>
                <p>{{ profile.student.nik }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>NIP</small>
                <p>{{ profile.student.nip }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>No Peserta</small>
                <p>{{ profile.student.no_peserta }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>Golongan</small>
                <p>{{ profile.student.golongan }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>Instansi</small>
                <p>{{ profile.student.instansi }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>Jabatan</small>
                <p>{{ profile.student.jabatan }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>Pangkat</small>
                <p>{{ profile.student.pangkat }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>Unit Kerja</small>
                <p>{{ profile.student.unit_kerja }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>Agama</small>
                <p>{{ profile.student.agama }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>Alamat Tinggal</small>
                <p>{{ profile.student.alamat_tinggal }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>Alamat KTP</small>
                <p>{{ profile.student.alamat_ktp }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>Jenis Kelamin</small>
                <p>{{ profile.student.jenis_kelamin }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>Tempat Lahir</small>
                <p>{{ profile.student.tempat_lahir }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="6">
              <section>
                <small>Tanggal Lahir</small>
                <p>{{ profile.student.tanggal_lahir }}</p>
              </section>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-dialog v-model="progress" hide-overlay persistent width="250">
        <v-card class="indigo" dark>
          <v-card-text>
            silahkan tunggu...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { EDIT_STUDENT } from "../../../graphql/Student";
export default {
  name: "addGroup",

  props: ["dialogView", "profile", "idProfile", "compareEmail"],

  data() {
    return {
      progress: false,
      role: localStorage.getItem("role_id")
    };
  },

  methods: {
    updatePeserta() {
      this.progress = true;
      if (this.compareEmail != this.profile.email) {
        const id = parseInt(this.profile.id);
        const id_profile = parseInt(this.idProfile);
        this.$apollo
          .mutate({
            mutation: EDIT_STUDENT,
            variables: {
              id: id,
              nama_lengkap: this.profile.nama_lengkap,
              email: this.profile.email,
              id_profile: id_profile,
              connect: id,
              tipe_avatar: this.profile.tipe_avatar
            }
          })
          .then(() => {
            this.$store
              .dispatch("resendEmail", {
                id: parseInt(this.profile.id)
              })
              .then(() => {
                this.progress = false;
                Swal.fire({
                  icon: "success",
                  title: "Email telah dikirim ulang",
                  showConfirmButton: false,
                  timer: 3000,
                  toast: true,
                  position: "top"
                });
                this.$emit("false");
              });
          })
          .catch(err => {
            console.log(err);
            ("errornya di graph");
            this.progress = false;
          });
      } else {
        this.$store
          .dispatch("resendEmail", {
            id: parseInt(this.profile.id)
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "email telah dikirim ulang",
              showConfirmButton: false,
              timer: 3000,
              toast: true,
              position: "top"
            });
            this.progress = false;
            this.$emit("false");
          });
      }
    }
  }
};
</script>

<style></style>
