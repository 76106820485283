<template>
  <div class="pa-2 min _bg-default">
    <v-card>
      <v-tabs
        v-model="tab"
        background-color="indigo"
        centered
        dark
        icons-and-text
      >
        <!-- <v-tabs-slider></v-tabs-slider> -->

        <v-tab href="#tab-1">
          Coach
          <v-icon>mdi-account-tie</v-icon>
        </v-tab>

        <v-tab href="#tab-2">
          Peserta
          <v-icon>mdi-account-multiple</v-icon>
        </v-tab>

        <v-tab href="#tab-3">
          Kelompok
          <v-icon>mdi-account-group</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :value="'tab-1'">
          <v-card flat>
            <ListMentor />
          </v-card>
        </v-tab-item>
        <v-tab-item :value="'tab-2'">
          <v-card flat>
            <ListStudent />
          </v-card>
        </v-tab-item>
        <v-tab-item :value="'tab-3'">
          <v-card flat>
            <ListGroup />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import ListMentor from "../Teacher/listMentor";
import ListStudent from "../Teacher/listStudent";
import ListGroup from "../Teacher/listGroup";
export default {
  name: "managementAccount",
  components: {
    ListMentor,
    ListStudent,
    ListGroup
  },
  data() {
    return {
      tab: null
    };
  }
};
</script>

<style scoped>
.min {
  min-height: 90vh;
}
._bg-default {
  background: #f4f6f9;
}
</style>
